import React, { useEffect, useState } from "react";
import { sessionValue } from "../../../types/api/sessionData";
import { PointButton } from "./PointButton";
import {ActiveSessionApi} from "../../../system/api/ActiveSessionApi";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers";
import {dispatch} from "../../../App";
import {actions} from "../../../redux";

type props = {
    setValues: sessionValue[],
}

const VotingSelection = (props: props)  => {

    const sessionApi: ActiveSessionApi = new ActiveSessionApi();
    const [selectedValue, setSelectedValue] = useState<sessionValue|null>(null);

    const [setNumber, setSetNumber] = useState<number>(0);
    const [userName, setUserName] = useState<string>('');
    const [userEmail, setUserEmail] = useState<string>('');
    const [showVotingResult, setShowVotingResult] = useState<boolean>(false);
    const [itemSelected, setItemSelected] = useState<boolean>(false);

    useSelector((state: RootState) => {
        if (state.userSession.userSessionName !== userName) {
            setUserName(state.userSession.userSessionName);
        }
        if (state.userSession.userSessionEmail !== userEmail) {
            setUserEmail(state.userSession.userSessionEmail);
        }
        if (state.userSession.userSessionNumber !== setNumber) {
            setSetNumber(state.userSession.userSessionNumber);
        }
        if (state.userSession.userSessionShowResults !== showVotingResult) {
            setShowVotingResult(state.userSession.userSessionShowResults);
        }
    });

    const selectValue =(selectValue: sessionValue) => {
        if (showVotingResult) {
            return;
        }
        if (selectValue === selectedValue) {
          setSelectedValue(null);
          return;
        }
        setSelectedValue(selectValue);
      }

    const setVoting = async () => {
        await sessionApi.setVoting(setNumber, userEmail, selectedValue);
    }

    useEffect(()=>{
       if (!showVotingResult) {
           setVoting();
       }
    },[selectedValue]);

    useEffect(() => {
        if (showVotingResult) {
            setSelectedValue(null);
            return;
        }
    }, [showVotingResult]);

    return (
        <div className="VotingSelection">
              <div className="row">
        {
        props.setValues.map((item, itemIndex) => (
            <div className="col-auto" key={itemIndex}>
              <PointButton setValue={item} show={true} selected={selectedValue === item} onSelect={() => {
                selectValue(item);
              }}></PointButton>
            </div>
        ))
      }
      </div>
        </div>
    );
};

export { VotingSelection }