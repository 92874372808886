import React, { useEffect, useState } from "react";
import Icon from '@mdi/react';
import { mdiAt, mdiAccountOutline } from '@mdi/js';
import { actions } from "../../redux";
import { localStorageHelper } from "../../helper/localStorage";
import { dispatch } from "../../App";

import './signIn.scss';

const SignInComponent = () => {
    const [userName, setUserName] = useState<string>('');
    const [userEmail, setUserEmail] = useState<string>('');
    const [rememberData, setRememberData] = useState<boolean>(false);

    const sighnIn = () => {
        dispatch(actions.userSession.setEmail(userEmail));
        dispatch(actions.userSession.setName(userName));

        if (rememberData) {
            const helper = new localStorageHelper();
            helper.setUserSessionData(userEmail, userName);
        }
    };

    useEffect(() => {
        const helper = new localStorageHelper();
        const data = helper.getUserSessionData();
        if (data.userEmail && data.userName) {
            dispatch(actions.userSession.setEmail(data.userEmail));
            dispatch(actions.userSession.setName(data.userName));
            setUserEmail(data.userEmail);
            setUserName(data.userName);
        }
    }, []);


    return (
        <div className="SignIn container">
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="label-email">
                            <Icon path={mdiAt} size={1} />
                        </span>
                        <input type="email"
                            className="form-control"
                            placeholder="E-Mailadresse"
                            aria-label="E-Mailadresse"
                            aria-describedby="label-email"
                            value={userEmail}
                            onChange={(evt) => setUserEmail(evt.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="label-name">
                            <Icon path={mdiAccountOutline} size={1} />
                        </span>
                        <input type="text"
                            className="form-control"
                            placeholder="Anzeigename"
                            aria-label="Anzeigename"
                            aria-describedby="label-name"
                            value={userName}
                            onChange={(evt) => setUserName(evt.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-check">
                        <input className="form-check-input"
                            type="checkbox"
                            value={rememberData ? 1 : 0}
                            onChange={(evt) => setRememberData(evt.target.checked)}
                            id="flexCheckDefault"
                        />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Anmeldedaten merken
                        </label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                    <button type="button" className="btn btn-outline-primary" onClick={sighnIn}>Anmelden</button>
                </div>
            </div>
        </div>
    );
};

export { SignInComponent };
