import React from "react";
import { sessionValue } from "../../../types/api/sessionData";
import { LazyIcon } from "../../LazyIcon/LazyIcon";


import "./PointButton.scss";

type props = {
    setValue: sessionValue,
    selected: boolean,
    show: boolean,
    onSelect: () => void
}

const PointButton = (props:props) => {

    let className: string = 'pointButton';
    if (props.selected) {
        className += ' selected';
    }

    return (
        <span className={className} onClick={props.onSelect}>{
            props.setValue.showText === '' ? 
            <span className="value">{props.setValue.value}</span>
            : <>{
                props.setValue.showText.startsWith('mdi') ? 
                <span className="value"><LazyIcon icon={props.setValue.showText} size={1.75}></LazyIcon></span>:
                <span className="value">{props.setValue.showText}</span>}</>
        }</span>
    )

}

export { PointButton };
