import React, { useEffect, useState } from "react";

import './EditSetIcon.scss';
import {sessionIcon} from "../../../types/api/sessionData";
import {LazyIcon} from "../../LazyIcon/LazyIcon";
import Icon from "@mdi/react";
import {mdiTrashCanOutline} from "@mdi/js";



type props = {
    iconSet: sessionIcon,
    onChange: (data: sessionIcon) => void,
    onDelete: () => void,
};

const EditSetIcon = (props: props)  => {

    const [valueName, setValueName] = useState<string>(props.iconSet.iconName);

    const [iconSet, setIconSet] = useState<sessionIcon>(props.iconSet)


    useEffect(() => {
        const newValue = { ...iconSet };
        newValue.iconName = valueName;
        setIconSet(newValue);
    }, [valueName]);

    useEffect(() => {
        props.onChange(iconSet);
    }, [iconSet])

    return (
        <div className="editSetIcon">
            <div className="row align-items-center mb-2 pb-2">
                <div className="col-auto input-group d-inline-flex w-auto align-items-center">
                    <span className="input-group-text" id="set-name">IconId (mdi-IconSet)</span>
                    <input type="text"
                           className="form-control me-2"
                           placeholder="Text"
                           aria-label="Text der angezeigt werden soll"
                           aria-describedby="set-name"
                           value={valueName}
                           onChange={(evt) => setValueName(evt.target.value)}
                    />
                </div>
                <div className="col-1">{
                    valueName.startsWith('mdi') ?
                        <LazyIcon icon={valueName} size={1}></LazyIcon>
                        : <span className="d-inline-block"></span>
                }</div>
                <div className="col">
                    <button className="btn btn-outline-primary" onClick={props.onDelete}><Icon path={mdiTrashCanOutline}
                                                                                               size={1}></Icon></button>
                </div>
            </div>
        </div>
    );
}

export {EditSetIcon};
