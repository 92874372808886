import axios, { RawAxiosRequestConfig, AxiosResponse } from 'axios';
import { graphQlHeader } from '../../types/api/graphQlTypes';
import { appConfig } from '../config/appConfig';
import { DeviceIdService } from '../../helper/deviceIdService';

abstract class ApiCaller {
  protected controller: AbortController;
  protected deviceIdService: DeviceIdService;

  constructor() {
    this.controller = new AbortController();
    this.deviceIdService = new DeviceIdService();
  }

  public abortRequest(): boolean {
    this.controller.abort();
    return true;
  }

  protected async query<T>(
    apiCall: string,
    body: object = {},
    accessToken: string = '',
    callbackProgress?: (percentage: number) => void
  ) {
    const source = axios.CancelToken.source();

    const objHeader: graphQlHeader = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Deviceid': this.deviceIdService.getDeviceId(),
    };

    if (accessToken !== '') {
      objHeader.Authorization = 'Bearer ' + accessToken;
    }

    const axiosClient = axios.create();
    axiosClient.defaults.baseURL = appConfig.api.url;
    axiosClient.defaults.timeout = 19000;
    axiosClient.defaults.withCredentials = true;

    axios.interceptors.request.use(
      (request) => {
        request.headers.set('Access-Control-Allow-Origin', '*');
        request.headers.set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS');
        request.headers.set('Access-Control-Allow-Headers', '*');
        request.headers.set('Content-Type', 'application/json');
        request.headers.set('Accept', 'application/json');
        request.headers.set('Deviceid', this.deviceIdService.getDeviceId());
        return request;
      },
      (error) => {
        return error;
      },
      { synchronous: true }
    );

    axios.interceptors.response.use(
      (response) => {
        //Dispatch any action on success
        return response;
      },
      function (error) {
        if (error.response.status === 401) {
          //Add Logic to
          //1. Redirect to login page or
          //2. Request refresh token
        }
        return Promise.reject(error);
      }
    );

    let config: RawAxiosRequestConfig = {};

    if (typeof callbackProgress !== 'undefined') {
      config = {
        headers: objHeader,
      };
    } else {
      config = {
        headers: objHeader,
      };
    }

    return axiosClient
      .post<T>(
        appConfig.api.url + apiCall,
        body,
        config
      )
      .then((response: AxiosResponse<T>) => {
        return response.data as T;
      })
      .catch((error) => {
        //console.error('api call error: ', error, error.message, apiCall, accessToken);
        return undefined as T;
      });
  }
}

export { ApiCaller };
