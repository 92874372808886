import { localStorageHelper } from "./localStorage";

class DeviceIdService {

    private localStorageService: localStorageHelper

    constructor(
      
    ) { 
      this.localStorageService = new localStorageHelper();
    }
  
    getDeviceId(): string {
      let deviceId = this.localStorageService.getItem<string>('deviceId');
      if (typeof deviceId !== 'string' || deviceId === '') {
        deviceId = this.generateUUIDV4();
        localStorage.setItem('deviceId', deviceId);
      }
      return deviceId;
    }
  
    private generateUUIDV4(): string {
      // http://www.ietf.org/rfc/rfc4122.txt
      const s: string[] = [];
      const hexDigits = '0123456789abcdef';
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = '4'  // bits 12-15 of the time_hi_and_version field to 0010
      // eslint-disable-next-line no-bitwise
      const spc19 = isNaN(parseInt(s[19])) ? 0 : parseInt(s[19]);
  
      s[19] = hexDigits.substr(((spc19 & 0x3) | 0x8), 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = '-';
  
      const uuid: string = s.join('');
      return uuid;
    }
  }

  export { DeviceIdService };
