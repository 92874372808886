import React, {useState} from 'react';

import  "./Emoticons.scss";
import Icon from "@mdi/react";
import {mdiDotsVerticalCircleOutline} from "@mdi/js";
import {activeSessionUser} from "../../../types/api/activeSessionData";
import {sessionIcon} from "../../../types/api/sessionData";
import {LazyIcon} from "../../LazyIcon/LazyIcon";
import {ActiveSessionApi} from "../../../system/api/ActiveSessionApi";

type props = {
    sessionUser: activeSessionUser,
    icons: sessionIcon[],
    setNumber: number,
};

const Emoticons = (props: props) => {

    const sessionApi: ActiveSessionApi = new ActiveSessionApi();
    const [className, setClassName] = useState<string>('emoticons');

    const sendIcon = async (icon: sessionIcon) => {
        await sessionApi.sendEmoticon(props.setNumber, icon.iconName, props.sessionUser.email);
        setClassName('emoticons');
    }

    const toggleVisibility = () => {
        if (className === 'emoticons') {
            setClassName('emoticons active');
            return;
        }
        setClassName('emoticons');
    }

    return (<div className={className}>
        <span onClick={toggleVisibility}><Icon path={mdiDotsVerticalCircleOutline} size={1}/></span>
        <ul>
            {
                props.icons.map((icon: sessionIcon, index: number) => (
                    <li className="mb-2">
                        <button className="btn btn-outline-primary ms-1" onClick={() => {
                            sendIcon(icon);
                        }}>
                            <LazyIcon icon={icon.iconName} size={1}></LazyIcon>
                        </button>
                    </li>
                ))
            }
        </ul>
    </div>);
}

export {Emoticons};