import { useLocation, useParams } from "react-router-dom";
import { useCurrentURLType } from "../types/hooks/useCurrentUrlType";

const useCurrentURL = (): useCurrentURLType => {
    const location = useLocation();
    const params = useParams();

    let pageNumber: number = 0;

    let split = '/set/';
    if (location.pathname.indexOf('/editSet/') !== -1) {
        split = '/editSet/';
    }
    const path = location.pathname.split(split);
    const setIdFromPath = path[1] ?? '0';
    pageNumber = parseInt(setIdFromPath);

    return {
      pathname: location.pathname,
      search: location.search,
      params: params,
      pageNumber: pageNumber,
    };
};

export {useCurrentURL};
