import React, { useState } from "react";
import {dispatch} from "../../App";
import {actions} from "../../redux";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reducers";
import {appConfig} from "../../system/config/appConfig";

const Websocket = () => {

    const [setNumber, setSetNumber] = useState<number>(0);
        
    const url = new URL(appConfig.websocket.url);
    url.searchParams.append('topic', 'pointing-poker-data');
    url.searchParams.append('setNumber', setNumber.toString());

    const eventSource = new EventSource(url);

    eventSource.onmessage = event => {

        const eventData = JSON.parse(event.data);
        const eventKey = eventData.key ?? '';
        if (eventKey.endsWith('sendEmoticon')) {
            dispatch(actions.emoticon.setData({
                targetEmail: eventData.value.targetUser ?? '',
                emoticon: eventData.value.icon ?? '',
                isActive: !!eventData.value.targetUser,
            }));
            return;
        }

        // Handle the event
        const dataValue = eventData.value;
        dispatch(actions.websocket.setActiveSession(dataValue));
    };

    useSelector((state: RootState) => {
        if (state.userSession.userSessionNumber !== setNumber) {
            setSetNumber(state.userSession.userSessionNumber);
        }
    });

    return (<></>);
};

export { Websocket };
