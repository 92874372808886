import {activeSession} from "../types/api/activeSessionData";

const initActiveSession: activeSession = {
    _id: "",
    average: 0,
    expiresAfter: "",
    setNumber: 0,
    showVoting: false,
    users: []
};

export { initActiveSession };
