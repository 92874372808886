import React, {useState} from "react";
import { sessionData } from "../../types/api/sessionData";
import { useNavigate } from "react-router-dom";

import "./ListOwnSetEntry.scss";
import Icon from "@mdi/react";
import { mdiPencil } from "@mdi/js";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reducers";

type props = {
    setData: sessionData,
};

const ListOwnSetEntry = (props: props) => {

    const navigate = useNavigate();

    const [userEmail, setUserEmail] = useState<string>('');

    useSelector((state: RootState) => {
        if(state.userSession.userSessionEmail !== userEmail) {
            setUserEmail(state.userSession.userSessionEmail);
        }
    });


    const showSet = () => {
        navigate('/set/' + props.setData.number);
    }

    const editSet = () => {
        navigate('/editSet/' + props.setData.number);
    }


    return (<div className="listOwnSetEntry mt-2 d-flex flex-row justify-content-between align-items-stretch">
        <span className="d-flex col pe align-items-center ps-3" onClick={showSet}>{props.setData.name} ({props.setData.number})</span>
        <>{userEmail === props.setData.email
            ? <button className="btn btn-outline-primary" onClick={editSet}><Icon path={mdiPencil} size={1}></Icon></button>
            : null}
        </>
    </div>);
}

export {ListOwnSetEntry};
