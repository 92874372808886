import React, {useState} from "react";
import Icon from "@mdi/react";
import {mdiInformation} from "@mdi/js";

import './Infobox.scss';
import {infoboxProps} from "../../types/component/infobox";

const Infobox = (props:infoboxProps) => {
    const [showInfo, setShowInfo] = useState(false);

    const toggleInfo = () => {
        setShowInfo(!showInfo);
    };

    const createMarkup = () => {
        return { __html: props.message };
    };


    return (
        <div className="infobox">
            <a onClick={toggleInfo}><Icon path={mdiInformation} size={1} /></a>
            {showInfo ?
                <div className="card bg-light">
                    <div className="card-header">{props.title}</div>
                    <div className="card-body">
                        <div className="card-text" dangerouslySetInnerHTML={createMarkup()}></div>
                    </div>
                </div>
                : null}
        </div>
    );
}

export { Infobox };
