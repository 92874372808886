import React, { useEffect, useState } from "react";
import { sessionData, sessionValue } from "../../../types/api/sessionData";
import { SetDataApi } from "../../../system/api/SetDataApi";
import { activeSession, activeSessionUser } from "../../../types/api/activeSessionData";
import { VotingSelection } from "./VotingSelection";
import { VotingUserList } from "./VotingUserList";
import Icon from "@mdi/react";
import { mdiEmoticonCryOutline } from "@mdi/js";
import {dispatch} from "../../../App";
import {actions} from "../../../redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import {initActiveSession} from "../../../inital/activeSessionData";
import {ActiveSessionApi} from "../../../system/api/ActiveSessionApi";
import {EmoticonAnim} from "./EmoticonAnim";

type props = {
  pageNumber: number;
  userEmail: string;
};

const SetshowComponent = (props: props) => {
  const initSetData: sessionData = {
    _id: "",
    name: "",
    number: props.pageNumber,
    email: props.userEmail,
    password: "",
    settings: {
      unit: "",
      values: [],
      icons: [],
    },
    guests: [],
    votings: [],
  };

  const setApi: SetDataApi = new SetDataApi();
  const sessionApi: ActiveSessionApi = new ActiveSessionApi();

  const [initLoadData, setInitLoadData] = useState<boolean>(false);
  const [setData, setSetData] = useState<sessionData>(initSetData);
  const [activeSession, setActiveSession] = useState<activeSession>(initActiveSession);

  const [setValues, setSetValues] = useState<sessionValue[]>([]);

  const [userAccepted, setUserAccepted] = useState<boolean>(false);
  const [userDenied, setUserDenied] = useState<boolean>(false);
  const [sessionUsers, setSessionUsers] = useState<activeSessionUser[]>([]);

  const [userName, setUserName] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');

  const addValue = (value: sessionValue) => {
    setSetValues((prevValues) => [...prevValues, { ...value }]);
  };

  const loadSetData = async () => {
    await setApi.loadSetData(props.pageNumber).then((response: sessionData) => {
      if (response) {
        const newSetValues = response.settings.values;
        newSetValues.sort((a, b) => a.value - b.value);
        setSetData(response);

        setSetValues([]);
        newSetValues.forEach((newValue: sessionValue) => {
          addValue(newValue);
        });

        loadSessionData();
      }
    });
  };

  const loadSessionData = async () => {
    await sessionApi.loadActiveSession(props.pageNumber, props.userEmail, userName).then((response: activeSession) => {
      setSessionUsers(response.users ?? []);

      dispatch(actions.userSession.showResult(response.showVoting));
      dispatch(actions.userSession.setAverage(response.average));

      if(response.clearVoting) {
        dispatch(actions.userSession.setCleared(response.clearVoting));
      }
    });
  }

  useSelector((state: RootState) => {
    if (state.userSession.userSessionName !== userName) {
        setUserName(state.userSession.userSessionName);
    }
    if (state.userSession.userSessionEmail !== userEmail) {
        setUserEmail(state.userSession.userSessionEmail);
    }
    if(state.websocket.sessionData && state.websocket.sessionData !== activeSession) {
      setActiveSession(state.websocket.sessionData);
    }
});

  useEffect(() => {
    if (!activeSession) {
      return;
    }
    setSessionUsers(activeSession.users ?? []);

    dispatch(actions.userSession.showResult(activeSession.showVoting));
    dispatch(actions.userSession.setAverage(activeSession.average));

    if(activeSession.clearVoting) {
      dispatch(actions.userSession.setCleared(activeSession.clearVoting));
    }

  }, [activeSession]);


  useEffect(() => {
    sessionUsers.forEach((user: activeSessionUser) => {
      if (user.email === props.userEmail) {
        setUserAccepted(user.accepted);
        setUserDenied(user.denied);
        user = {
          ...user, 
          voting: user.voting
        };
      }
    });
  }, [sessionUsers]);

  useEffect(() => {
    if (initLoadData === false) {
      loadSetData();
      setInitLoadData(true);
    }
    dispatch(actions.userSession.setNumber(props.pageNumber));
  }, []);

  return (
    <div className="setShow container">
      {
        userDenied ? (<>
          <div className="row">
            <div className="col">Sorry ... <br />
            der Zugang wurde abgewiesen <Icon path={mdiEmoticonCryOutline} size={1} />
            </div>
          </div>
        </>) : (<>
          <EmoticonAnim />
          <div className="row mb-3">
            <div className="col"><span className="display-5">{setData.name}</span> ({setData.settings.unit})</div >
          </div >
          {userAccepted ? <>
            <VotingSelection setValues={setValues} ></VotingSelection >
            <>{
              sessionUsers.length > 0 ? <VotingUserList sessionUsers={sessionUsers} icons={setData.settings.icons} setNumber={setData.number}></VotingUserList> : null
            }</>
          </> : <>
            <div className="row">
              <div className="col">warte bis du zum raum zugelassen wirst</div>
            </div>
          </>}
        </>)
      }
    </div >
  );
};

export { SetshowComponent };
