import { combineReducers } from 'redux';
import { userSesssionReducer } from './userSession/reducer';
import { systemReducer } from "./system/reducer";
import { websocketReducer } from "./websocket/reducer";
import {emoticonReducer} from "./emoticon/reducer";

const reducers = combineReducers({
    userSession: userSesssionReducer,
    system: systemReducer,
    websocket: websocketReducer,
    emoticon: emoticonReducer,
});

export { reducers };

export type RootState = ReturnType<typeof reducers>;
