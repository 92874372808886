import {EmoticonInterface} from "./interface";
import {EMOTICON_ACTIONS, EmoticonState} from "./types";

const initialState: EmoticonState = {
    targetEmail: '',
    emoticon: '',
    isActive: false
};

const emoticonReducer = (
    state: EmoticonState | undefined,
    action: EmoticonInterface
):EmoticonState  => {
    if (typeof state === 'undefined') {
        state = initialState;
    }

    switch (action.type) {
        case EMOTICON_ACTIONS.emoticonSetData:
            return {
                ...state,
                targetEmail: action.payload.targetEmail,
                emoticon: action.payload.emoticon,
                isActive: action.payload.isActive
            }
        case EMOTICON_ACTIONS.emoticonClearData:
            return initialState;
        default:
            return state;
    }
}

export { emoticonReducer };
