import {SystemInterface} from "./interface";
import {SYSTEM_ACTIONS, SystemState} from "./types";

const initialState: SystemState = {
    themeDark: true
};

const systemReducer = (
    state: SystemState | undefined,
    action: SystemInterface
):SystemState  => {
    if (typeof state === 'undefined') {
        state = initialState;
    }

    switch (action.type) {
        case SYSTEM_ACTIONS.systemToggleDarkTheme:
            const themeStatus = state.themeDark;
            return {
                ...state,
                themeDark: !themeStatus
            }
        default:
            return state;
    }
}

export { systemReducer };
