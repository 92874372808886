import {WEBSOCKET_ACTIONS, WebsocketState} from "./types";
import {WebsocketInterface} from "./interface";
import {initSetData} from "../../inital/sesstionData";
import {initActiveSession} from "../../inital/activeSessionData";

const initialState: WebsocketState = {
    sessionData: initActiveSession
};

const websocketReducer = (
    state: WebsocketState | undefined,
    action: WebsocketInterface
):WebsocketState  => {
    if (typeof state === 'undefined') {
        state = initialState;
    }

    switch (action.type) {
        case WEBSOCKET_ACTIONS.setActiveSessionData:
            return {
                ...state,
                sessionData: action.payload
            }
        default:
            return state;
    }
}

export { websocketReducer };
