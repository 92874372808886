import {EMOTICON_ACTIONS, EmoticonState} from "./types";

export const emoticonSetData = (payload: EmoticonState) => {
    return {
        type: EMOTICON_ACTIONS.emoticonSetData,
        payload: payload
    };
};

export const emoticonClearData = () => {
    return {
        type: EMOTICON_ACTIONS.emoticonClearData,
    }
}
