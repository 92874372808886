import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Dispatch } from 'redux';

import { EditSet, Home, Set } from './pages';

import { HeadComponent } from './components';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from "./redux/reducers";
import {useEffect, useState} from "react";


import './App.scss';
import {Websocket} from "./components/Websocket/Websocket";

export let dispatch: Dispatch;
export let locationPath: string;


const App = () => {

  dispatch = useDispatch();

  const [darkMode, setDarkMode] = useState<boolean>(true);
  const [theme, setTheme] = useState<string>('dark');

  useSelector((state: RootState) => {
    if(state.system.themeDark !== darkMode) {
      setDarkMode(state.system.themeDark);
    }
  });

  useEffect(() => {
    if (darkMode === false) {
      setTheme('light');
      return;
    }

    setTheme('dark');
  }, [darkMode]);


  return (
    <div className="App" data-bs-theme={theme}>
      <Router>
        <Websocket></Websocket>
        <HeadComponent ></HeadComponent>
        <Routes>
          <Route path="/set/:id" Component={Set} />
          <Route path="/editSet/:id" Component={EditSet} />
          <Route path="/" Component={Home} />
          <Route path="*" Component={Home} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
