export enum USER_SESSION_ACTIONS {
    setUserSessionEmail = 'SET_USER_SESSION_EMAIL',
    setUserSessionName = 'SET_USER_SESSION_NAME',
    setUserSessionNumber = 'SET_USER_SESSION_NUMBER',
    setUserSessionShowResults = 'SET_USER_SESSION_SHOW_RESULTS',
    setUserSessionVotingSet = 'SET_USER_SESSION_VOTING_SET',
    setUserSessionAverage = 'SET_USER_SESSION_AVERAGE',
    setUserSessionIsCleared = 'SET_USER_SESSION_IS_CLEARED',
};

export type UserSessionState = {
    userSessionEmail: string,
    userSessionName: string,
    userSessionNumber: number,
    userSessionShowResults: boolean,
    userSessionVotingSet: boolean,
    userSessionIsCleared: boolean,
    userSessionAverage: number,
};
