import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";

import './head.scss';
import Icon from "@mdi/react";
import {mdiLightSwitch, mdiLightSwitchOff, mdiLogout} from "@mdi/js";
import {dispatch } from "../../App";
import { actions } from "../../redux";
import { localStorageHelper } from "../../helper/localStorage";
import {useNavigate} from "react-router-dom";


const HeadComponent = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState<string>('');
    const [userEmail, setUserEmail] = useState<string>('');
    const [darkMode, setDarkMode] = useState<boolean>(true);

    useSelector((state: RootState) => {
        if (state.userSession.userSessionName !== userName) {
            setUserName(state.userSession.userSessionName);
        }
        if(state.userSession.userSessionEmail !== userEmail) {
            setUserEmail(state.userSession.userSessionEmail);
        }
        if(state.system.themeDark !== darkMode) {
            setDarkMode(state.system.themeDark);
        }
    });

    const goHome = () => {
        navigate('/');
    }

    const getSwitch = () => {
        if(!darkMode) {
            return <Icon path={mdiLightSwitchOff} size={1} />
        }

        return <Icon path={mdiLightSwitch} size={1} />
    };

    const toggleSwitch = () => {
        dispatch(actions.system.toggleDarkTheme());
    }


    const logOut = () => {
        dispatch(actions.userSession.setEmail(''));
        dispatch(actions.userSession.setName(''));
        setUserName('');
        setUserEmail('');
        const localStorage = new localStorageHelper();
        localStorage.setUserSessionData('', '');
    }

    return (
        <div className="headComponent container-fluid mb-3">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col"><a onClick={goHome}><img className="badCode" src="/bad-code-head.png" alt="logo" /></a></div>
                    <div className="col text-center">Pointig Poker</div>
                    <div className="col-auto">{userName}</div>
                    {userEmail ? 
                        <>
                            <div className="col-auto">&lt;{userEmail}&gt;</div> 
                            <div className="col-auto"><a onClick={logOut}><Icon className="logOut" path={mdiLogout} size="1"/></a></div>
                        </>
                    : null}
                    <div className="col-auto"><a onClick={toggleSwitch}>{getSwitch()}</a></div>
                </div>
            </div>
        </div>
    );
};

export { HeadComponent };