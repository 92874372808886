import React, { useEffect, useState } from "react";
import { sessionValue } from "../../../types/api/sessionData";
import Icon from "@mdi/react";
import { LazyIcon } from "../../LazyIcon/LazyIcon";
import { mdiTrashCanOutline } from "@mdi/js";
import {Infobox} from "../../Info/Infobox";

import { infoContent } from './infoValue';
import './EditSetValue.scss';

type props = {
    valueSet: sessionValue,
    onChange: (data: sessionValue) => void,
    onDelete: () => void,
};

const EditSetValue = (props: props) => {

    const [valueNumber, setValueNumber] = useState<number>(props.valueSet.value);
    const [valueName, setValueName] = useState<string>(props.valueSet.showText);
    const [valueNoCalculate, setValueNoCalculate] = useState<boolean>(props.valueSet.disableCalculation);

    const [valueSet, setValueSet] = useState<sessionValue>(props.valueSet);

    useEffect(() => {
        const newValue = { ...valueSet };
        newValue.value = valueNumber;
        setValueSet(newValue);
    }, [valueNumber]);

    useEffect(() => {
        const newValue = { ...valueSet };
        newValue.showText = valueName;
        setValueSet(newValue);
    }, [valueName]);

    useEffect(() => {
        const newValue = { ...valueSet };
        newValue.disableCalculation = valueNoCalculate;
        setValueSet(newValue);
    }, [valueNoCalculate]);

    useEffect(() => {
        props.onChange(valueSet);
    }, [valueSet])


    return (
        <div className="editSetValue">
            <div className="row align-items-center mb-2 pb-2">
                <div className="col-auto input-group d-inline-flex w-auto align-items-center">
                    <span className="input-group-text" id="set-value">Wert</span>
                    <input type="number"
                           step="0.1"
                           className="form-control"
                           placeholder="0"
                           aria-label="0"
                           aria-describedby="set-value"
                           value={valueNumber}
                           onChange={(evt) => setValueNumber(parseFloat(evt.target.value))}
                    />
                </div>
                <div className="col-auto input-group d-inline-flex w-auto align-items-center">
                    <span className="input-group-text" id="set-name">Anzeige</span>
                    <input type="text"
                           className="form-control me-2"
                           placeholder="Text"
                           aria-label="Text der angezeigt werden soll"
                           aria-describedby="set-name"
                           value={valueName}
                           onChange={(evt) => setValueName(evt.target.value)}
                    />
                </div>
                <div className="col-1">{
                    valueName.startsWith('mdi') ?
                        <LazyIcon icon={valueName} size={1}></LazyIcon>
                        : valueName !== '' ?
                            <span className="d-inline-block">{valueName}</span>
                            : <span className="d-inline-block">{valueNumber}</span>
                }</div>
                <div className="col-auto"><Infobox title={infoContent.title} message={infoContent.message}/></div>
                <div className="col-auto form-check form-switch d-inline-flex w-auto align-items-center">
                    <input className="form-check-input me-1"
                           type="checkbox" id="flexSwitchCheckDefault"
                           value={valueNoCalculate ? 1 : 0}
                           checked={valueNoCalculate}
                           onChange={(evt) => setValueNoCalculate(evt.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Wert nicht berechnen</label>
                </div>
                <div className="col">
                    <button className="btn btn-outline-primary" onClick={props.onDelete}><Icon path={mdiTrashCanOutline}
                                                                                               size={1}></Icon></button>
                </div>
            </div>
        </div>
    );
}

export {EditSetValue};
