class localStorageHelper {

    setItem(storage: string, data: unknown) {

        const json = JSON.stringify(data);
        localStorage.setItem(storage, json);

    }

    getItem<T>(storage: string): T | null{

        let obj: T | undefined = undefined;

        const json = localStorage.getItem(storage);

        try {
            if (json !== null) {
                return JSON.parse(json) as T;
            }
        } catch (e) {

            if (json !== null) {
                return json as T;
            }
        }

       return null;
    }

    deleteItem(storage: string) {
        localStorage.removeItem(storage);
    }

    clearStorage() {
        localStorage.clear();
    }

    setUserSessionData(userEmail: string, userName: string) {
        this.setItem('userSessionEmail', userEmail)
        this.setItem('userSessionName', userName);
    }

    getUserSessionData() {
        let userEmail = this.getItem<string>('userSessionEmail');
        if (!userEmail) {
            userEmail = '';
        }

        let userName = this.getItem<string>('userSessionName');
        if (!userName) {
            userName = '';
        }

        return {
            userEmail: userEmail,
            userName: userName
        }
    }


};

export { localStorageHelper };
