import React, {useEffect, useState} from 'react'
import  CryptoJS from 'crypto-js';
import {useSelector} from "react-redux";

import "./EmoticonAnim.scss"
import {RootState} from "../../../redux/reducers";
import {dispatch} from "../../../App";
import {actions} from "../../../redux";
import {LazyIcon} from "../../LazyIcon/LazyIcon";

const EmoticonAnim = () => {

    const [emoticon, setEmoticon] = useState<string>('');
    const [targetEmail, setTargetEmail] = useState<string>('');
    const [activeEmoticon, setActiveEmoticon] = useState<boolean>(false);
    const [userEmail, setUserEmail] = useState<string>('');
    const [userEmailHash, setUserEmailHash] = useState<string>('');

    useSelector((state: RootState) => {
        if (state.emoticon.emoticon !== emoticon) {
            setEmoticon(state.emoticon.emoticon);
        }
        if (state.emoticon.targetEmail !== targetEmail) {
            setTargetEmail(state.emoticon.targetEmail);
        }
        if(state.emoticon.isActive !== activeEmoticon) {
            setActiveEmoticon(state.emoticon.isActive);
        }

        if (state.userSession.userSessionEmail !== userEmail) {
            setUserEmail(state.userSession.userSessionEmail);
        }

    });

    const showEmoticon = () => {

        let animClass = 'animEmoticon';
        let style = {};
        if (userEmail === targetEmail) {
            animClass += ' ' + 'selfTarget';
        } else {

            const startPos = getStartPosition();
            const targetPos = getElementPosition(userEmailHash);
            style=
            {
                '--start-x': `${startPos.x}px`,
                '--start-y': `${startPos.y}px`,
                '--target-x': `${targetPos.x}px`,
                '--target-y': `${targetPos.y}px`,
            };

            animClass += ' ' + 'foreignTarget';
        }

        return (<div className={animClass} style={style}>
            <LazyIcon icon={emoticon} size={1} />
        </div>);
    }

    const getStartPosition = () => {
        const x = Math.random() * window.innerWidth; // Zufälliger X-Wert innerhalb des Bildschirms
        const y = Math.random() * window.innerHeight; // Zufälliger Y-Wert innerhalb des Bildschirms
        return {
            x: x,
            y: y,
        };
    };

    const getElementPosition = (hash: string) => {
        const element = document.getElementById(hash);
        let x = 0;
        let y = 0;
        if (element) {
            const rect = element.getBoundingClientRect();
            x = rect.left + window.scrollX;
            y = rect.top + window.scrollY;
        }
        return {
            x: x,
            y: y,
        };
    };

    useEffect(() => {
        if (!activeEmoticon) {
            return;
        }

        setTimeout(() => {
            dispatch(actions.emoticon.clearData());
        }, 1000);

    }, [activeEmoticon]);

    useEffect(() => {
        const hash = CryptoJS.MD5(targetEmail).toString();
        setUserEmailHash(hash);
        console.log(hash, targetEmail);
    }, [targetEmail]);

    return (<>
        {activeEmoticon ? <>{showEmoticon()}</> : null}
    </>);
};

export { EmoticonAnim };
