import {UserSessionInterface} from "./interface";
import {USER_SESSION_ACTIONS, UserSessionState} from "./types";

const initialState: UserSessionState = {
    userSessionEmail: "",
    userSessionName: "",
    userSessionNumber: 0,
    userSessionVotingSet: false,
    userSessionShowResults: false,
    userSessionAverage: 0,
    userSessionIsCleared: false,
};

const userSesssionReducer = (
    state: UserSessionState | undefined,
    action: UserSessionInterface
):UserSessionState  => {
    if (typeof state === 'undefined') {
        state = initialState;
    }

    switch (action.type) {
        case USER_SESSION_ACTIONS.setUserSessionEmail:
            return {
                ...state,
                userSessionEmail: action.payload
            };
        case USER_SESSION_ACTIONS.setUserSessionName:
            return {
                ...state,
                userSessionName: action.payload
            };
        case USER_SESSION_ACTIONS.setUserSessionNumber:
            return {
                ...state,
                userSessionNumber: action.payload
            };
        case USER_SESSION_ACTIONS.setUserSessionVotingSet:
            return {
                ...state,
                userSessionVotingSet: action.payload
            }
        case USER_SESSION_ACTIONS.setUserSessionShowResults:
            return {
                ...state,
                userSessionShowResults: action.payload
            }
        case USER_SESSION_ACTIONS.setUserSessionAverage:
            return {
                ...state,
                userSessionAverage: action.payload
            }
        case USER_SESSION_ACTIONS.setUserSessionIsCleared:
            return {
                ...state,
                userSessionIsCleared: action.payload
            }
        default:
            return state;    
    }
}

export { userSesssionReducer };
