import {
    setAverage, setSessionIsCleared,
    setUserSessionEmail,
    setUserSessionName,
    setUserSessionNumber,
    setUserShowResults,
    setUserVotingSet
} from "./userSession/action";
import {systemToggleDarkTheme} from "./system/action";
import {websocketSetActiveSession} from "./websocket/action";
import {emoticonClearData, emoticonSetData} from "./emoticon/action";

const actions = {
    userSession: {
        setEmail: setUserSessionEmail,
        setName: setUserSessionName,
        setNumber: setUserSessionNumber,
        showResult: setUserShowResults,
        votingSet: setUserVotingSet,
        setAverage: setAverage,
        setCleared: setSessionIsCleared,
    },
    system: {
        toggleDarkTheme: systemToggleDarkTheme,
    },
    websocket: {
        setActiveSession: websocketSetActiveSession
    },
    emoticon: {
        setData: emoticonSetData,
        clearData: emoticonClearData
    }
};

export { actions };
