import { USER_SESSION_ACTIONS } from './types';

export const setUserSessionEmail = (email: string) => {
    return {
      type: USER_SESSION_ACTIONS.setUserSessionEmail,
      payload: email,
    };
};

export const setUserSessionName = (name: string) => {
    return {
      type: USER_SESSION_ACTIONS.setUserSessionName,
      payload: name,
    };
};

export const setUserSessionNumber = (session: number) => {
    return {
      type: USER_SESSION_ACTIONS.setUserSessionNumber,
      payload: session,
    };
};

export const setUserShowResults= (showResult: boolean) => {
    return {
      type: USER_SESSION_ACTIONS.setUserSessionShowResults,
      payload: showResult,
    };
};

export const setUserVotingSet = (votingSet: boolean) => {
    return {
      type: USER_SESSION_ACTIONS.setUserSessionVotingSet,
      payload: votingSet,
    };
};

export const setAverage = (average: number) => {
    return {
      type: USER_SESSION_ACTIONS.setUserSessionAverage,
      payload: average,
    };
};

export const setSessionIsCleared = (isCleared: boolean) => {
    return {
      type: USER_SESSION_ACTIONS.setUserSessionIsCleared,
      payload: isCleared,
    };
};
