import {infoboxProps} from "../../../types/component/infobox";

const infoContent: infoboxProps = {
    title: 'Anzeige',
    message: '<p>Wenn kein gesondterte Anzeigewert übertagen wird, dann ist die Standardanzeige der numerische Wert.</p>' +
        '<p>Als Basis für die Icons ist die "mdi icon library" (<a href="https://pictogrammers.com/library/mdi/" target="_blank">link<a>) implementiert. ' +
        'Es können alle Icons in der "react-Notation" implementiert werden.</p>'
}

export { infoContent };
