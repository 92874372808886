import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { SignInComponent, SetshowComponent } from "../../components";
import { useCurrentURL } from "../../hooks/useCurrentURL";
// import { useNavigate } from "react-router-dom";

const Set = () => {

    
    const useCurentUrl = useCurrentURL();


    const [userEmail, setUserEmail] = useState<string>('');

    useSelector((state: RootState) => {
        if (state.userSession.userSessionEmail !== userEmail) {
            setUserEmail(state.userSession.userSessionEmail);
        }
    }); 

    return (
        <div className="Home">
            {
                userEmail ?
                    <SetshowComponent userEmail={userEmail} pageNumber={useCurentUrl.pageNumber} ></SetshowComponent>
                    :
                    <SignInComponent></SignInComponent>
            }
        </div>
    )
}

export { Set };
