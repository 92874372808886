import { appConfigType } from "../../types/api/apiConfig";

const appConfigDev: appConfigType = {
    api: {
        url: 'http://localhost:18001/'
    },
    websocket: {
        url: 'http://localhost:18001/.well-known/mercure'
    }
};

const appConfigProd: appConfigType = {
    api: {
        url: 'https://pointing-api.bad-code.de/'
    },
    websocket: {
        url: 'https://pointing-api.bad-code.de/.well-known/mercure',
    }
}

const appConfig: appConfigType = process.env.NODE_ENV !== "production" ? appConfigDev : appConfigProd;

export { appConfig };
