import React, { useState } from "react";
import { DashboardComponent, SignInComponent } from "../../components";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";

const Home = () => {

    const [userEmail, setUserEmail] = useState<string>('');

    useSelector((state: RootState) => {
        if (state.userSession.userSessionEmail !== userEmail) {
            setUserEmail(state.userSession.userSessionEmail);
        }
    });

    return (
        <div className="Home">
            {
                userEmail ?
                    <DashboardComponent email={userEmail}></DashboardComponent>
                    :
                    <SignInComponent></SignInComponent>
            }

        </div>
    )
}

export { Home };
