import React, { useEffect, useState } from "react";

import { Icon as MDIcon } from "@mdi/react";

type props = {
    icon: string,
    size: number,
}

const LazyIcon = (props: props) => {

    const [iconPath, setIconPath] = useState<string>('');

    useEffect(() => {
        import('@mdi/js').then((icons:any) => {
            if (icons[props.icon]) {
                const icon = icons[props.icon];
                setIconPath(icon.toString());
            } else {
                setIconPath('');
            }
        });
    }, [props.icon]);

    return (<>{iconPath !== '' ?
        <MDIcon path={iconPath} size={props.size}></MDIcon>
        : null
    }</>);

}

export { LazyIcon };
