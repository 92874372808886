import React, {useEffect, useState} from "react";
import  CryptoJS from 'crypto-js';
import {activeSessionUser} from "../../../types/api/activeSessionData";
import Icon from "@mdi/react";
import {
    mdiAccountCancelOutline,
    mdiAccountCheckOutline,
    mdiCheck,
    mdiHelp
} from "@mdi/js";

import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers";
import {ActiveSessionApi} from "../../../system/api/ActiveSessionApi";
import {LazyIcon} from "../../LazyIcon/LazyIcon";
import {sessionIcon} from "../../../types/api/sessionData";

import "./VotingUser.scss";
import {Emoticons} from "./Emoticons";

type props = {
    sessionUser: activeSessionUser,
    setShow: boolean,
    icons: sessionIcon[],
    setNumber: number,
}


const VotingUser = (props: props) => {

    const sessionApi: ActiveSessionApi = new ActiveSessionApi();


    const [setNumber, setSetNumber] = useState<number>(0);
    const [userName, setUserName] = useState<string>('');
    const [userEmail, setUserEmail] = useState<string>('');
    const [showVotingResult, setShowVotingResult] = useState<boolean>(false);
    const [userEmailHash, setUserEmailHash] = useState<string>('');

    useSelector((state: RootState) => {
        if (state.userSession.userSessionName !== userName) {
            setUserName(state.userSession.userSessionName);
        }
        if (state.userSession.userSessionEmail !== userEmail) {
            setUserEmail(state.userSession.userSessionEmail);
        }
        if (state.userSession.userSessionNumber !== setNumber) {
            setSetNumber(state.userSession.userSessionNumber);
        }
        if (state.userSession.userSessionShowResults !== showVotingResult) {
            setShowVotingResult(state.userSession.userSessionShowResults);
        }
    });

    const acceptUser = async () => {
        await sessionApi.acceptUser(setNumber, userEmail, props.sessionUser.email, props.sessionUser.name)
    }

    const denyUser = async () => {
        await sessionApi.denyUser(setNumber, userEmail, props.sessionUser.email, props.sessionUser.name)
    }

    const userVotingResult = () => {
        let path: string = mdiHelp;
        if (props.sessionUser.voting) {
            path = mdiCheck;
        }
        if (!showVotingResult) {
            return (
                <div className="votingResult"><Icon path={path} size={1}/></div>
            );
        }

        if (!props.sessionUser.voting) {
            return (<span className="emptyVoting"></span>);
        }

        if (props.sessionUser.voting.showText) {
            return (<>{props.sessionUser.voting.showText.startsWith('mdi') ?
                <span className="value"><LazyIcon icon={props.sessionUser.voting.showText}
                                                  size={1.75}></LazyIcon></span>:
                <span className="value">{props.sessionUser.voting.showText}</span>
            }</>)
        }

        return (<span className="value">{props.sessionUser.voting?.value}</span>)
    };

    const userIconList = () => {
        if (props.sessionUser.email === userEmail) {
            return (<div></div>);
        }

        return (<Emoticons icons={props.icons} sessionUser={props.sessionUser} setNumber={props.setNumber} ></Emoticons>);
    }

    const showUserName = () => {
        let name = props.sessionUser.name;
        if (!name) {
            name = '&lt;' + props.sessionUser.email + '&gt';
        }
        return (<>{name}</>);
    }

    useEffect(() => {
        const hash = CryptoJS.MD5( props.sessionUser.email).toString();
        setUserEmailHash(hash);
        console.log(hash, props.sessionUser.email);
    }, [ props.sessionUser]);

    return (
        <>{
            props.sessionUser.denied ? null : (
                <div className="votingUser row pt-1 pb-1 align-items-center" id={userEmailHash}>
                    <div className="col-1">{
                        props.sessionUser.accepted ? <>{userIconList()}</> : null
                    }</div>
                    <div className="col-6">{showUserName()}</div>
                    <div className="col-2 d-inline-flex  justify-content-end">
                        {
                            props.sessionUser.accepted ? (
                                <>{userVotingResult()}</>
                            ) : (
                                <>
                                    <button className="btn btn-outline-primary ms-1" onClick={acceptUser}><Icon
                                        path={mdiAccountCheckOutline} size={1}/></button>
                                    <button className="btn btn-outline-primary ms-1" onClick={denyUser}><Icon
                                        path={mdiAccountCancelOutline} size={1}/></button>
                                </>
                            )
                        }
                    </div>
                </div>
            )
        }</>

    );
}

export {VotingUser};
