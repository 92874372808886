import { activeSession } from '../../types/api/activeSessionData';
import { ApiCaller } from './apiCaller';
import {sessionValue} from "../../types/api/sessionData";

class ActiveSessionApi extends ApiCaller
{
    public async loadActiveSession(setNumber: number, userEmail: string, userName: string) {
        const call = 'activeSession';

        const param = {
            'setNumber': setNumber,
            'userEmail': userEmail,
            'userName': userName,
        };

        this.abortRequest();
        return await this.query<activeSession>(call, param);
    }

    public async setVoting(setNumber: number, userEmail: string, voting: sessionValue | null) {
        const call = 'setVoting';

        const param = {
            'setNumber': setNumber,
            'userEmail': userEmail,
            'voting': voting
        }

        this.abortRequest();
        return await this.query<activeSession>(call, param);
    }

    public async clearVoting(setNumber: number, userEmail: string) {
        const call = 'clearVoting';

        const param = {
            'setNumber': setNumber,
            'userEmail': userEmail,
            'showVoting': false
        };

        this.abortRequest();
        return await this.query<activeSession>(call, param);
    }
    public async showVoting(setNumber: number, userEmail: string) {
        const call = 'showVoting';

        const param = {
            'setNumber': setNumber,
            'userEmail': userEmail,
            'showVoting': true
        };

        this.abortRequest();
        return await this.query<activeSession>(call, param);
    }

    public async acceptUser(setNumber: number, userEmail: string, userAccept: string, acceptName: string) {
        const call = 'activeSessionAccept';

        const param = {
            'setNumber': setNumber,
            'userEmail': userEmail,
            'acceptUser': {
                'email': userAccept,
                'name': acceptName
            }
        }

        this.abortRequest();
        return await this.query<activeSession>(call, param);
    }

    public async denyUser(setNumber: number, userEmail: string, userAccept: string, acceptName: string) {
        const call = 'activeSessionDeny';

        const param = {
            'setNumber': setNumber,
            'userEmail': userEmail,
            'acceptUser': {
                'email': userAccept,
                'name': acceptName
            }
        }

        this.abortRequest();
        return await this.query<activeSession>(call, param);
    }

    public async sendEmoticon(setNumber:number, emoticon: string, userEmail: string) {
        const call = 'sendEmoticon';

        const param = {
            'setNumber': setNumber,
            'emoticon': emoticon,
            'userEmail': userEmail,
        }

        this.abortRequest();
        return await this.query<activeSession>(call, param);

    }
}

export { ActiveSessionApi };
