import React, { useState } from "react";
import { SeteditComponent, SignInComponent } from "../../components";
import { UserSessionComponent } from "../../components/UserSession/UserSession";

const EditSet = () => {

    const [setNumber, setSetNumber] = useState<number>(0);
    const [userEmail, setUserEmail] = useState<string>('');


    const initData = (email: string, number: number) => {
        setUserEmail(email);
        setSetNumber(number);
    }

    return (
        <div className="editSet">
            <UserSessionComponent onInit={initData}></UserSessionComponent>
            {
                userEmail !== '' ?
                    <>{!isNaN(setNumber) ?
                        <SeteditComponent pageNumber={setNumber} userEmail={userEmail}></SeteditComponent>
                        : null}</>
                    :
                    <SignInComponent></SignInComponent>
            }

        </div>

    )
};

export { EditSet };
