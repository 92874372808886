import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../redux/reducers";

type props = {
    onInit: (userEmail: string, setNumbner: number) => void
};

const UserSessionComponent = (props: props) => {

    const location = useLocation();
    const [userEmail, setUserEmail] = useState<string>('');
    const [setId, setSetId] = useState<string>('0');

    useSelector((state: RootState) => {
        if (state.userSession.userSessionEmail !== userEmail) {
            setUserEmail(state.userSession.userSessionEmail);
        }
    });

    useEffect(() => {
        console.log(setId);
        props.onInit(userEmail, parseInt(setId));
    }, [setId, userEmail])

    useEffect(() => {
        let split = '/set/';
        if (location.pathname.indexOf('/editSet/') !== -1) {
            split = '/editSet/';
        }
        const path = location.pathname.split(split);
        const setIdFromPath = path[1] ?? '';
        setSetId(setIdFromPath);
    }, []);


    return <></>;
};

export { UserSessionComponent };
