import React, { useEffect, useState } from "react";
import {sessionData, sessionIcon, sessionSettings, sessionValue} from "../../../types/api/sessionData";
import Icon from "@mdi/react";
import { mdiPlus } from "@mdi/js";
import { EditSetValue } from "./EditSetValue";
import { EditSetIcon } from "./EditSetIcon";
import { SetDataApi } from "../../../system/api/SetDataApi";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";

type props = {
    pageNumber: number,
    userEmail: string,
}

type listSessionValue = sessionValue & {
    key?: string
}

type listIconValue = sessionIcon & {
    key?: string
}

const SeteditComponent = (props:props) => {
    const navigate = useNavigate();
    
    const initSetData:sessionData = {
        _id: "",
        name: "",
        number: props.pageNumber,
        email: props.userEmail,
        password: "",
        settings: {
            unit: "",
            values: [],
            icons: []
        },
        guests: [],
        votings: []
    };
    const setApi: SetDataApi = new SetDataApi();

    const [initLoadData, setInitLoadData] = useState<boolean>(false);
    const [setData, setSetData] = useState<sessionData>(initSetData);

    const [setName, setSetName] = useState<string>('');
    const [setUnit, setSetUnit] = useState<string>('');
    const [setValues, setSetValues] = useState<listSessionValue[]>([]);
    const [setIcons, setSetIcons] = useState<listIconValue[]>([]);


    const addIcon = (icon: sessionIcon) => {
        setSetIcons(newIcons => [
            ...newIcons,
            { ...icon, key: uuidv4() }
        ]);
    }

    const addNewIcon = () => {
        addIcon({
            iconName: "",
        })
    };

    const changeSetIcon = (data: sessionIcon, itemIndex: number) => {
        const newIcons = [...setIcons];
        newIcons[itemIndex] = data;
        setSetIcons(newIcons);
    };

    const removeSetIcon = (itemIndex: number) => {

    }

    const addValue = (value: sessionValue) => {
        setSetValues(prevValues => [
            ...prevValues,
            { ...value, key: uuidv4() }
        ]);
    };
    
    const addNewValue = () => {
        addValue({
            value: 0,
            showText: "",
            disableCalculation: false
        })
    };

    const changeSetValue = (data: sessionValue, itemIndex: number) => {
        const newSetValues =  [...setValues];
        newSetValues[itemIndex] = data;
        setSetValues(newSetValues);
    };
    
    const removeValue = (itemIndex: number) => {
        setSetValues(prevValues => {
            return prevValues.filter((_, index) => index !== itemIndex);
        });
    }

    const saveSetData = async () => {

        if (setData.number === 0) {
            setData.email = props.userEmail;
        }
        await setApi.updateSet(setData).then((response: sessionData) => {
            if (response) {

                if (response.number !== 0 && props.pageNumber === 0) {
                    navigate('/editSet/' + response.number);
                    return;
                }

                setSetData(response);
                setSetName(response.name);
                setSetUnit(response.settings.unit);

                const newSetValues = response.settings.values;
                newSetValues.sort((a,b) => a.value - b.value);
                setSetValues([]);
                newSetValues.forEach((newValue: sessionValue) => {
                    addValue(newValue);
                });
            }
        });
    }

    const goHome = () => {
        navigate('/');
    };

    const loadSessionData = async () => {
        await setApi.loadSetData(props.pageNumber).then((response: sessionData) => {
            if (response) {

                if (response.email !== props.userEmail && props.pageNumber !== 0) {
                    navigate('/');
                }

                const newSetValues = response.settings.values;
                newSetValues.sort((a,b) => a.value - b.value);
                setSetData(response);
                setSetName(response.name);
                setSetUnit(response.settings.unit);

                setSetValues([]);
                newSetValues.forEach((newValue: sessionValue) => {
                    addValue(newValue);
                });

                setSetIcons(response.settings.icons);
            }
        });
    };

    useEffect(() => {
        const newSettings:sessionSettings = {
            ...setData.settings,
            unit: setUnit,
            values: setValues,
            icons: setIcons,
        };

        const newSetData:sessionData = {
            ...setData,
            name: setName,
            settings: newSettings
        };
        
        setSetData(newSetData);
    },[setName, setUnit, setValues, setIcons]);

    useEffect(() => {
        if (initLoadData === false) {
            loadSessionData();
            setInitLoadData(true);
        }
    }, []);
    

    return (
        <div className="editSet container">
            <div className="row">
                <div className="row"><h2>{setName}&nbsp;</h2></div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="set-name">Bezeichnung</span>
                        <input type="text"
                               className="form-control"
                               placeholder="Bezeichnug"
                               aria-label="Bezeichnung"
                               aria-describedby="set-name"
                               value={setName}
                               onChange={(evt) => setSetName(evt.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="set-unit">Einheit</span>
                        <input type="text"
                               className="form-control"
                               placeholder="z.B. Stunden"
                               aria-label="z.B. Stunden"
                               aria-describedby="set-unit"
                               value={setUnit}
                               onChange={(evt) => setSetUnit(evt.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4 d-flex align-items-center justify-content-between mb-3">
                    <h5 className="d-inline-block pe-3">Aktion-Icons</h5>
                    <button className="btn btn-primary d-inline-block" type="button" onClick={addNewIcon}><Icon
                        path={mdiPlus} size={1}/></button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {setIcons.map((item, itemIndex) => <EditSetIcon
                        key={item.key}
                        iconSet={item}
                        onChange={(item) => {
                            changeSetIcon(item, itemIndex);
                        }}
                        onDelete={() => {
                            removeSetIcon(itemIndex);
                        }}
                    ></EditSetIcon>)}
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6 col-lg-4 d-flex align-items-center justify-content-between mb-3">
                    <h5 className="d-inline-block pe-3">Werte</h5>
                    <button className="btn btn-primary d-inline-block" type="button" onClick={addNewValue}><Icon
                        path={mdiPlus} size={1}/></button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {setValues.map((item, itemIndex) => <EditSetValue
                        key={item.key}
                        valueSet={item}
                        onChange={(item) => {
                            changeSetValue(item, itemIndex);
                        }}
                        onDelete={() => {
                            removeValue(itemIndex);
                        }}
                    ></EditSetValue>)}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <button type="button" className="btn btn-outline-primary" onClick={goHome}>&lt;&lt; Übersicht
                    </button>
                </div>
                <div className="col-auto">
                    <button type="button" className="btn btn-outline-primary" onClick={saveSetData}>Speichern</button>
                </div>
            </div>

        </div>
    );
}

export {SeteditComponent};
