import {WEBSOCKET_ACTIONS} from "./types";
import {activeSession} from "../../types/api/activeSessionData";

export const websocketSetActiveSession = (sessionData:activeSession) => {
    return {
        type: WEBSOCKET_ACTIONS.setActiveSessionData,
        payload: sessionData
    };
};

