export enum EMOTICON_ACTIONS {
    emoticonSetData = 'EMOTICON_SET_DATA',
    emoticonClearData = 'EMOTICON_CLEAR_DATA',
};

export type EmoticonState = {
    targetEmail: string,
    emoticon: string,
    isActive: boolean,
};
