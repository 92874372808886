import React, {useState} from "react";
import {activeSessionUser} from "../../../types/api/activeSessionData";
import {VotingUser} from "./VotingUser";


import "./VotingUserList.scss";
import {ActiveSessionApi} from "../../../system/api/ActiveSessionApi";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers";
import {dispatch} from "../../../App";
import {actions} from "../../../redux";
import {sessionIcon} from "../../../types/api/sessionData";

type props = {
    setNumber: number,
    sessionUsers: activeSessionUser[],
    icons: sessionIcon[],
}

const VotingUserList = (props: props) => {
    const sessionApi: ActiveSessionApi = new ActiveSessionApi();

    const [setNumber, setSetNumber] = useState<number>(0);
    const [userEmail, setUserEmail] = useState<string>('');
    const [showVotingResult, setShowVotingResult] = useState<boolean>(false);
    const [averageValue, setAverageValue] = useState<number>(0)


    useSelector((state: RootState) => {
        if(state.userSession.userSessionEmail !== userEmail) {
            setUserEmail(state.userSession.userSessionEmail);
        }
        if(state.userSession.userSessionNumber !== setNumber) {
            setSetNumber(state.userSession.userSessionNumber);
        }
        if(state.userSession.userSessionShowResults !== showVotingResult) {
            setShowVotingResult(state.userSession.userSessionShowResults);
        }
        if(state.userSession.userSessionAverage !== averageValue) {
            setAverageValue(state.userSession.userSessionAverage);
        }
    });

    const showVoting = async () => {
        await sessionApi.showVoting(setNumber, userEmail);
    }

    const clearVoting = async () => {
        await sessionApi.clearVoting(setNumber, userEmail);
    }

    return (
        <div className="votingUserList mt-3 ps-3">
            <div className="row mb-4">
                <div className="col-4">
                    <button className="btn btn-outline-info" onClick={showVoting}>anzeigen</button>
                </div>
                <div className="col-4 d-inline-flex justify-content-end">
                    <button className="btn btn-outline-info" onClick={clearVoting}>zurücksetzen</button>
                </div>
            </div>
            {
                props.sessionUsers.map((sessionUser: activeSessionUser, index: number) => (
                    <VotingUser key={index} sessionUser={sessionUser} setShow={false} icons={props.icons} setNumber={props.setNumber}></VotingUser>
                ))
            }
            {
                showVotingResult ? (
                <div className="row mt-2">
                    <div className="col-8 d-inline-flex justify-content-end"><span className="averageValue">{averageValue}</span></div>
                </div>
                ): null
            }
        </div>
    );
}

export {VotingUserList};
