import React, {useEffect} from "react"
import { useNavigate } from 'react-router-dom';
import { ListOwnSets } from "./ListOwnSets";
import {dispatch} from "../../App";
import {actions} from "../../redux";

type props = {
    email: string;
};

const DashboardComponent = (props: props) => {

    const navigate = useNavigate();
    const newSet = () => {
        navigate('/editSet/0');
    };

    useEffect(() => {
        dispatch(actions.userSession.setNumber(0));
    }, []);

    return (
        <div className="dashboard container">
            <div className="row">
                <div className="col">
                    <ListOwnSets email={props.email}></ListOwnSets>
                </div>
                <div className="col">
                    <button type="button" className="btn btn-outline-primary" onClick={newSet}>neues Set erstellen</button>
                </div>
            </div>
        </div>
    );
}

export { DashboardComponent };