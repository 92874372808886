import React, { useEffect, useState } from "react";
import { UserSessionComponent } from "../UserSession/UserSession";
import { SetDataApi } from "../../system/api/SetDataApi";
import { sessionData, sessionListResponse } from "../../types/api/sessionData";
import { ListOwnSetEntry } from "./ListOwnSetEntry";

type props = {
    email: string
}

const ListOwnSets = (props: props) => {

    const setApi: SetDataApi = new SetDataApi();
    const [listOwnSets, setListOnwSets] = useState<sessionData[]>([]);

    const loadUserList = async () => {
        await setApi.loadUserList(props.email).then((response: sessionListResponse) => {
            if (response && response.data) {
                setListOnwSets(response.data);
            }
        });
    }

    useEffect(() => {
        loadUserList();
    }, []);


    return (
        <div className="ListOwnSets">
            {
                listOwnSets.map((setData: sessionData) => <ListOwnSetEntry key={setData._id} setData={setData}></ListOwnSetEntry>)
            }
        </div>
    );
}

export { ListOwnSets };
