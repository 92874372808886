import { sessionData, sessionListResponse } from "../../types/api/sessionData";
import { ApiCaller } from "./apiCaller";

class SetDataApi extends ApiCaller {

    public async loadSetData(
        setNumber: number
    ) {

        const call = 'set/' + setNumber;

        const param = {};

        this.abortRequest();

        return await this.query<sessionData>(call, param);
    }

    public async loadUserList(
        email: string
    ) {
        const call = 'set/email';
        const param = {
            email: email,
        };

        this.abortRequest();

        return await this.query<sessionListResponse>(call, param);
    }

    public async updateSet(
        setData:sessionData
    ) {
        const call = 'set/update';
        this.abortRequest();

        return await this.query<sessionData>(call, setData);
    }

};

export { SetDataApi }